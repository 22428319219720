import '../../styles/registry/userPortal.scss';
import { useRecoilState } from 'recoil';
import { Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import { RegistryHeader } from './RegistryHeader';
import { MassBalance } from './MassBalance';
import { Sidebar } from './Sidebar';
import { downloadComponent } from '../../recoil/registry';
import { PoS } from './ProofOfSustainability/PoS';

export function UserPortal() {
  const [isDownloadComponent] = useRecoilState(downloadComponent);
  // const optionId = useRecoilValue(activeOptionId);

  return (
    <div
      className={classNames('user', {
        activeDataExport: isDownloadComponent,
      })}
    >
      <RegistryHeader />
      <Routes>
        <Route path="/MassBalance" element={<MassBalance />} />
        <Route path="PoS/*" element={<PoS />} />
      </Routes>
      <Sidebar />
    </div>
  );
}
