import { atom } from 'recoil';

// users records
type PoSRecord = {
  _id: string;
  dateOfSupply: string;
  uniqueNumber: string;
  uniqueNumber2: string;
  dateOfIssuance: string;
  contractNumber: string;
  date: Date;
};

export const Records = atom<PoSRecord[]>({
  key: 'Records',
  default: [],
});

export const IsRecords = atom<boolean>({
  key: 'IsRecords',
  default: false,
});

export const FormSteps = atom<number>({
  key: 'FormSteps',
  default: 1,
});

// Editing records Id
export const EditRecordId = atom<string>({
  key: 'EditRecordId',
  default: '',
});
