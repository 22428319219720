// import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import '../../../styles/registry/PoS/addRecord.scss';
import { useEffect, useState } from 'react';
// import axios from 'axios';
import axios from 'axios';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { GradientTopButton } from './Forms/buttons';
import { EditRecordId, Records } from '../../../recoil/PoS';

const apiURL = process.env.REACT_APP_API_URL;

export function AddRecord() {
  const navigate = useNavigate();
  // const SetIsRecord = useSetRecoilState(IsRecords);
  const setRecordsId = useSetRecoilState(EditRecordId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');
  const [recordsPoS, setRecordsPoS] = useRecoilState(Records);

  const fetchRecords = async () => {
    try {
      const userId = localStorage.getItem('ID');
      const response = await axios.get(`${apiURL}/PoS/${userId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setRecordsPoS(response.data.data.posRecords);
    } catch (error) {
      console.error('Failed to fetch records:', error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const displayMessageTemporarily = (message: string) => {
    setSubmitStatus(message);
    setTimeout(() => {
      setSubmitStatus('');
    }, 3000); // Clears the message after 3 seconds
  };

  const createNewPoSRecord = async () => {
    setIsSubmitting(true);
    setSubmitStatus('');
    try {
      await axios.post(
        `${apiURL}/PoS`,
        {
          userId: localStorage.getItem('ID'),
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      displayMessageTemporarily('Success! New PoS record created.');
      navigate('/userPortal/PoS/step1');
    } catch (error) {
      console.error('Error creating new PoS record:', error);
      displayMessageTemporarily('Error creating new PoS record.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditRecord = (recordId: string) => {
    navigate('/userPortal/PoS/step1');
    setRecordsId(recordId);
  };

  const handleDeleteRecord = async (recordId: string) => {
    setIsSubmitting(true);
    try {
      await axios.delete(`${apiURL}/PoS/${recordId}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      displayMessageTemporarily('Record deleted successfully.');
      fetchRecords();
    } catch (error) {
      console.error('Failed to delete the record:', error);
      displayMessageTemporarily('Error deleting the record.');
    } finally {
      setIsSubmitting(false);
      console.log('Record deleted:', recordId);
    }
  };

  return (
    <div className="addRecords">
      <button
        type="button"
        className="green-addss"
        aria-label="add button"
        onClick={createNewPoSRecord}
        disabled={isSubmitting}
      />

      {submitStatus && <p className="text-white">{submitStatus}</p>}
      <div className="prev-recordBox">
        <h3 className="gray-subText">Previous Records</h3>
        <ul className="records-box">
          {recordsPoS.map((record) => (
            <li className="record-element" key={record._id}>
              {record._id}
              <GradientTopButton
                onClick={() => handleEditRecord(record._id)}
                buttonText="edit"
              />
              <GradientTopButton
                onClick={() => handleDeleteRecord(record._id)}
                buttonText="delete"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
