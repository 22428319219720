import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import loginPageData from '../../data/loginPage.json';
import { loginErrorMsg, loginForm } from '../../recoil/authPage';
import { AuthHeader } from './AuthHeader';

export const initialFormData = {
  email: '',
  password: '',
};

export function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useRecoilState(loginForm);
  const [errorMsg, setErrorMsg] = useRecoilState<string>(loginErrorMsg);

  const apiURL = process.env.REACT_APP_API_URL;
  const showBackBtn = true;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      console.log('API URL:', apiURL);

      const response = await fetch(`${apiURL}/users/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        setErrorMsg(data.message);
        return;
      }

      localStorage.setItem('ID', data.data.userId);
      navigate('/userPortal');
      setFormData(initialFormData);
      setErrorMsg('');
    } catch (error) {
      console.log('error', error, 1222);
      setErrorMsg('Something went wrong. Please try later.');
    }
  };

  return (
    <>
      <AuthHeader showBackBtn={showBackBtn} />
      <div className="layout">
        <div className="form-layout">
          <h1 className="title-green">{loginPageData.title}</h1>
          <form onSubmit={handleSubmit} className="green-input-container">
            {loginPageData.fields.map((field) => (
              <div key={field.id}>
                <label className="form-name">{field.title}</label>
                <input
                  key={field.id}
                  type={field.type}
                  placeholder={field.placeholder}
                  name={field.name}
                  value={formData[field.name as keyof typeof formData]}
                  onChange={handleChange}
                  autoComplete={field?.autoComplete}
                  className={`form-control  ${errorMsg ? 'is-invalid' : ''}`}
                />
                {field.name === 'password' && errorMsg && (
                  <div className="invalid-feedback">{errorMsg}</div>
                )}
              </div>
            ))}

            <div className="btnContainer">
              <input type="submit" value="submit" className="btn-reset" />
            </div>
          </form>

          <button
            className="btn-clear"
            type="button"
            onClick={() => navigate('/forgotPassword')}
          >
            Forgot password?
          </button>
        </div>

        <div className="island reset" />
      </div>
    </>
  );
}
