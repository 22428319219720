import { useSetRecoilState } from 'recoil';
import '../../styles/main.scss';
import { useNavigate } from 'react-router-dom';
import { currentStepState } from '../../recoil/authPage';

export function Main() {
  const navigate = useNavigate();
  const setCurrentStep = useSetRecoilState(currentStepState);

  return (
    <div className="main">
      <header className="main-header">
        <h1>My App</h1>

        <button
          type="button"
          className="buttons"
          onClick={() => {
            navigate('/signup');
            setCurrentStep(1);
          }}
        >
          Signup
        </button>

        <button
          type="button"
          className="buttons"
          onClick={() => navigate('/login')}
        >
          Login
        </button>
      </header>
    </div>
  );
}
