interface GradientTopButtonProps {
  onClick: () => void;
  buttonText: string;
}

export function GradientTopButton({
  onClick,
  buttonText,
}: GradientTopButtonProps) {
  return (
    <button
      className="px-4 py-2 rounded-full relative bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600 ml-20"
      onClick={onClick}
      type="button"
    >
      <div className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl bg-gradient-to-r from-transparent via-teal-500 to-transparent" />
      <span className="relative z-20">{buttonText}</span>
    </button>
  );
}
