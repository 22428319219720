import React from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import StepIndicator from '../../utils/StepIndicator';
import formFields from '../../../data/signupPage.json';
import { dataType } from '../../../utils/authPage/signupUtils';
import {
  currentStepState,
  signupError,
  signupForm1,
} from '../../../recoil/authPage';

interface ErrorMessages {
  [key: string]: string;
}

type Field = {
  id: string;
  type: string;
  title: string;
  placeholder: string;
  name: string;
  autocomplete?: string;
};

export const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
};
const apiURL = process.env.REACT_APP_API_URL;

export function Signup1() {
  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const [formData, setFormData] = useRecoilState(signupForm1);
  const [errorMsg, setErrorMsg] = useRecoilState<ErrorMessages>(signupError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiURL}/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.errors) {
          setErrorMsg(data.errors);
        } else if (data.message === 'Email address already exists.') {
          setErrorMsg({ email: 'Email already exists' });
        }
        throw new Error(data.message || 'Something went wrong');
      }

      localStorage.clear();
      setFormData(initialFormData);
      setErrorMsg({});
      setCurrentStep(2);
      localStorage.setItem('ID', data.data.userId);
    } catch (error: unknown) {
      console.error('Error:', error);
    }
  };

  const triggerFormSubmit = () => {
    const hiddenSubmitButton = document.getElementById('hiddenSubmit');
    if (hiddenSubmitButton) {
      hiddenSubmitButton.click();
    }
  };

  return (
    <div className="form-layout">
      <StepIndicator currentStep={currentStep} />
      <h1 className="title-green">{formFields.title}</h1>
      <h6 className="subtitle-green">{formFields.subtitle}</h6>

      <form className="green-input-container" onSubmit={handleSubmit}>
        {formFields.fields.map((field: Field) => (
          <React.Fragment key={field.id}>
            <div className="form-name">{field.title}</div>
            <input
              type={field.type}
              placeholder={field.placeholder}
              name={field.name}
              autoComplete={field.autocomplete || 'off'}
              value={formData[field.name as keyof dataType]}
              onChange={handleChange}
              className={classNames('form-control', {
                'is-invalid': errorMsg[field.name],
                'is-valid':
                  formData[field.name as keyof dataType] &&
                  !errorMsg[field.name],
              })}
            />
            {errorMsg[field.name] && (
              <div className="invalid-feedback d-block">
                {errorMsg[field.name]}
              </div>
            )}
          </React.Fragment>
        ))}
        <button type="submit" id="hiddenSubmit" style={{ display: 'none' }}>
          Submit
        </button>
      </form>

      <button className="btn-gonext" type="submit" onClick={triggerFormSubmit}>
        Go next
        <span className="arrow" aria-hidden="true" />
      </button>
    </div>
  );
}
