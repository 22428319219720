// CalendarComponent.tsx
import { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import '../../../styles/registry/MassBalance/calendar.scss';
import { enGB } from 'date-fns/locale';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CurrenDate,
  dataUpdateTrigger,
  datesWithDataOutput,
  selectedDateState,
} from '../../../recoil/registry';

registerLocale('en-GB', enGB);
const apiURL = process.env.REACT_APP_API_URL;

interface MassBalance {
  selectedDate: string;
}

export function Calendar() {
  const [selectedDate, setSelectedDate] = useRecoilState<Date | null>(
    selectedDateState
  );
  const [datesWithData, setDatesWithData] = useRecoilState(datesWithDataOutput);
  const setCurrentDate = useSetRecoilState(CurrenDate);
  const userId = localStorage.getItem('ID');
  const dataTrigger = useRecoilValue(dataUpdateTrigger);

  useEffect(() => {
    const fetchDatesWithData = async () => {
      try {
        const response = await fetch(`${apiURL}/mass-balance/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

        if (result.data && Array.isArray(result.data.massBalances)) {
          const formattedDates = result.data.massBalances.map(
            (massBalance: MassBalance) => new Date(massBalance.selectedDate)
          );
          setDatesWithData(formattedDates);
        } else {
          console.error('Unexpected structure:', result);
        }
      } catch (error) {
        console.error('Failed to fetch dates with data:', error);
      }
    };
    fetchDatesWithData();
  }, [dataTrigger]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = format(date, 'dd MMM yyyy');
      setCurrentDate(formattedDate);
    }
  };

  // Highlight dates with data
  const highlightWithClassName = (date: Date) =>
    datesWithData.find(
      (d) => format(d, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    )
      ? 'registered-date'
      : null;

  return (
    <DatePicker
      inline
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
      highlightDates={datesWithData}
      dayClassName={highlightWithClassName}
      locale="en-GB"
    />
  );
}
