import { useRecoilState, useRecoilValue } from 'recoil';
import '../../styles/exportComp/exportComp.scss';
import { useState } from 'react';
import {
  downloadComponent,
  massBalanceData,
  selectedEndDate,
  selectedStartDate,
} from '../../recoil/registry';
import { CalendarExport } from './CalendarExport';
import { SelectDays } from './SelectDays';
import { SelectMonths } from './SelectMonths';
import { exportToExcel } from './ExcelExport';

export function ExportDocument() {
  const [isDownloadComponent, setIsDownloadComponent] =
    useRecoilState(downloadComponent);
  const [activeButton, setActiveButton] = useState('dates');

  const startDate = useRecoilValue(selectedStartDate);
  const endDate = useRecoilValue(selectedEndDate);
  const data = useRecoilValue(massBalanceData);

  const startDateStr = startDate ? startDate.toISOString() : '';
  const endDateStr = endDate ? endDate.toISOString() : '';

  console.log(isDownloadComponent);

  return (
    <div className="date-picker-modal">
      <button
        type="button"
        onClick={() => setIsDownloadComponent(false)}
        className="btn-close-export"
        aria-label="close modal"
      />
      <header>
        <div className="select-period">
          <button
            type="button"
            className={`btnChoice ${activeButton === 'dates' ? 'activebtn' : ''}`}
            onClick={() => setActiveButton('dates')}
          >
            dates
          </button>

          <button
            type="button"
            className={`btnChoice ${activeButton === 'months' ? 'activebtn' : ''}`}
            onClick={() => setActiveButton('months')}
          >
            months
          </button>
        </div>
      </header>

      {activeButton === 'dates' ? (
        <div className="dateComp">
          <SelectDays />
          <div className="calendarBox">
            <CalendarExport />
          </div>
        </div>
      ) : (
        <SelectMonths />
      )}

      <footer>
        <div className="divider-horizontal" />
        <button
          type="button"
          className="export-btn"
          onClick={() => exportToExcel(data, startDateStr, endDateStr)}
        >
          <div className="downloadSvg" />
          export documents
        </button>
      </footer>
    </div>
  );
}
