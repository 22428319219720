import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../styles/resetPassword.scss';
import { AuthHeader } from './AuthHeader';

const apiURL = process.env.REACT_APP_API_URL;

export function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { token: resetToken } = useParams();
  const showBackBtn = true;
  const [successMsg, setSuccessMsg] = useState('');

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `${apiURL}/users/resetPassword/${resetToken}`,

        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: newPassword,
            passwordConfirm: confirmPassword,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccessMsg(
          'Your password has been successfully reset. You can now log in with your new password.'
        );
        setErrorMsg('');
        setTimeout(() => navigate('/login'), 5000);
      } else {
        const message =
          data.message || 'An error occurred during password reset.';
        setErrorMsg(message);
        setSuccessMsg('');
      }
    } catch (error) {
      setErrorMsg('Failed to reset password. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthHeader showBackBtn={showBackBtn} />
      <div className="layout">
        <div className="form-layout">
          <h1 className="title-green">Reset Password</h1>
          <div className="subtitle-green">
            Your new password should be different from old password
          </div>

          <form onSubmit={handleFormSubmit} className="green-input-container">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className="form-control mb-5"
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm New Password"
              className="form-control mb-4"
            />
            <button type="submit" disabled={isLoading} className="btn-reset">
              Reset Password
            </button>
          </form>
          {isLoading && <div className="text-white">Loading...</div>}
          {errorMsg && <div className="text-white">{errorMsg}</div>}
          {successMsg && <div className="text-success">{successMsg}</div>}
        </div>

        <div className="island reset" />
      </div>
    </>
  );
}
