import { useState } from 'react';
import { motion } from 'framer-motion';
import '../../styles/forgotPassword.scss';
import { AuthHeader } from './AuthHeader';

const apiURL = process.env.REACT_APP_API_URL;

export function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const showBackBtn = true;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMsg('');
    setErrorMsg('');

    try {
      const response = await fetch(`${apiURL}/users/forgotPassword`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();
      if (!response.ok) {
        setErrorMsg(data.message || 'An unexpected error occurred.');
      } else {
        setSuccessMsg(
          data.userMessage || 'A reset link has been sent to your email.'
        );
        setEmail('');
      }
    } catch (error) {
      setErrorMsg('Failed to connect to the server. Please try again later.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthHeader showBackBtn={showBackBtn} />
      <div className="layout">
        <motion.div className="form-layout">
          <h1 className="title-green">Reset Password</h1>
          <div className="subtitle-green">
            Please provide the email that you used to log in
          </div>
          <form
            onSubmit={handleFormSubmit}
            className="green-input-container forgotPassword"
          >
            <label htmlFor="registeredEmail" className="form-name">
              Registered E-mail
            </label>
            <div className="green-input-container">
              <input
                type="text"
                placeholder="johndoe@gmail.com"
                autoComplete="username"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
              />
            </div>

            <div className="btnContainer">
              <button type="submit" className="btn-reset">
                Reset password
              </button>
            </div>

            {isLoading && <div className="text-white">Loading...</div>}
            {successMsg && <div className="text-white">{successMsg}</div>}
            {errorMsg && <div className="text-white">{errorMsg}</div>}
          </form>
        </motion.div>

        <div className="island reset" />
      </div>
    </>
  );
}
