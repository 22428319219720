import { useState } from 'react';
import '../../../../styles/registry/PoS/forms/step2.scss';
import '../../../../styles/registry/PoS/Pos.scss';

interface Address {
  street: string;
  postalCode: string;
  city: string;
  country: string;
}

interface SupplierState {
  name: string;
  address: Address;
  certificationScheme: string;
  certificateId: string;
  isGroupMember: boolean;
}

interface RecipientState {
  name: string;
  address: Address;
  certificateId: string;
}

export function Step2() {
  const [supplier, setSupplier] = useState<SupplierState>({
    name: '',
    address: {
      street: '',
      postalCode: '',
      city: '',
      country: '',
    },
    certificationScheme: 'SURE-EU',
    certificateId: '',
    isGroupMember: false,
  });

  const [recipient, setRecipient] = useState<RecipientState>({
    name: '',
    address: {
      street: '',
      postalCode: '',
      city: '',
      country: '',
    },
    certificateId: '',
  });

  const handleSupplierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setSupplier((prevSupplier) => ({
      ...prevSupplier,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRecipient((prevRecipient) => ({
      ...prevRecipient,
      [name]: value,
    }));
  };

  // Add submit handler and other logic as needed

  return (
    <div className="info-container">
      <div className="form-container2">
        <div className="info-container">
          <h3 className="subtitle-Pos">Supplier</h3>
          <label className="input single-input">
            Name
            <input
              type="text"
              name="name"
              value={supplier.name}
              onChange={handleSupplierChange}
            />
          </label>
          <label className="input single-input">
            Address
            <input
              type="text"
              name="address"
              placeholder="Street No."
              value={supplier.address.street}
              onChange={handleSupplierChange}
            />
            <input
              type="text"
              name="address"
              placeholder="Postal code, city"
              value={supplier.address.postalCode}
              onChange={handleSupplierChange}
            />
            <input
              type="text"
              name="address"
              placeholder="Country"
              value={supplier.address.country}
              onChange={handleSupplierChange}
            />
          </label>
          <label className="input single-input">
            Certificate ID
            <input
              type="text"
              placeholder="SURE-EU/XX-Xxx/zxxxxxxxx"
              value={supplier.certificateId}
              onChange={handleSupplierChange}
            />
          </label>
          <label>
            <input
              type="checkbox"
              name="isGroupMember"
              checked={supplier.isGroupMember}
              onChange={handleSupplierChange}
            />
            I am member of a group certification
          </label>
        </div>

        <div className="info-container">
          <h3 className="subtitle-Pos">Recipient</h3>
          <label className="input single-input">
            Name
            <input
              type="text"
              name="name"
              value={recipient.name}
              onChange={handleRecipientChange}
            />
          </label>

          <label className="input single-input">
            Address
            <input
              type="text"
              name="address"
              placeholder="Street No."
              value={supplier.address.street}
              onChange={handleSupplierChange}
            />
            <input
              type="text"
              name="postal code, city"
              placeholder="Postal code, city"
              value={supplier.address.postalCode}
              onChange={handleSupplierChange}
            />
            <input
              type="text"
              name="Country"
              placeholder="Country"
              value={supplier.address.country}
              onChange={handleSupplierChange}
            />
          </label>

          <label className="input single-input">
            Certificate ID - if applicable
            <input
              type="text"
              placeholder="SURE-EU/XX-Xxx/zxxxxxxxx"
              value={supplier.certificateId}
              onChange={handleSupplierChange}
            />
          </label>
        </div>
      </div>

      <div className="Pos-btnContainer">
        <button className="btn-gonext" type="submit">
          Go next
          <span className="arrow" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
