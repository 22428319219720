// BurgerMenu.jsx
import React, { useState } from 'react';
import '../../../styles/registry/burgerMenu.scss'; // Assume you have some basic styles defined
import { useNavigate } from 'react-router-dom';

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem('ID');
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="burger-icon" type="button" onClick={toggleMenu}>
        ☰
      </button>

      {isOpen && (
        <div className="menu">
          <div className="btnbox">
            <button className="headerbtn profile" type="button">
              Profile
            </button>

            <button
              className="headerbtn logout"
              type="button"
              onClick={() => onLogout()}
            >
              Log out
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BurgerMenu;
