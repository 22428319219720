import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditRecordId, FormSteps, Records } from '../../../../recoil/PoS';
import '../../../../styles/registry/PoS/forms/step1.scss';
import '../../../../styles/registry/PoS/Pos.scss';

type PoSRecord = {
  _id: string;
  dateOfSupply: string;
  uniqueNumber: string;
  uniqueNumber2: string;
  dateOfIssuance: string;
  contractNumber: string;
  date: Date;
};

export function Step1() {
  const navigate = useNavigate();
  const [uniqueNumber, setUniqueNumber] = useState('');
  const [uniqueNumber2, setUniqueNumber2] = useState('');
  const [dateOfSupply, setDateOfSupply] = useState('');
  const [dateOfIssuance, setDateOfIssuance] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [step, setStep] = useRecoilState(FormSteps);
  const editingRecordId = useRecoilValue(EditRecordId);
  const recordsPoS = useRecoilValue<PoSRecord[]>(Records);

  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const currentEditingRecord = recordsPoS.find(
      (record) => record._id === editingRecordId
    );

    if (currentEditingRecord) {
      setUniqueNumber(currentEditingRecord.uniqueNumber || '');
      setUniqueNumber2(currentEditingRecord.uniqueNumber2 || '');
      setDateOfSupply(currentEditingRecord.dateOfSupply || '');
      setDateOfIssuance(currentEditingRecord.dateOfIssuance || '');
      setContractNumber(currentEditingRecord.contractNumber || '');
    }
  }, [recordsPoS, editingRecordId]);

  const handleNext = async () => {
    const updateData = Object.fromEntries(
      Object.entries({
        uniqueNumber,
        uniqueNumber2,
        dateOfSupply,
        dateOfIssuance,
        contractNumber,
        // eslint-disable-next-line no-unused-vars
      }).filter(([_, value]) => value !== null && value !== '')
    );

    try {
      await axios.patch(`${apiURL}/PoS/${editingRecordId}`, updateData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setStep(step + 1);
      navigate('/userPortal/PoS/step2');
    } catch (error) {
      console.error('Failed to update the record:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="info-container">
      <h5 className="sub">
        For biomass fuels according to the Renewable Energy Directive (EU)
        2018/2001 (RED II)
      </h5>

      <section className="info-container">
        <label htmlFor="uniqueNumber" className="single-input">
          Unique Number of Proof of Sustainability:
          <span>EU-SURE-PoS -</span>
          <input
            type="text"
            id="uniqueNumber"
            value={uniqueNumber}
            onChange={(e) => setUniqueNumber(e.target.value)}
            placeholder="SURE-ID (5 digits)"
            className="pos-input"
          />
          <input
            type="text"
            value={uniqueNumber2}
            onChange={(e) => setUniqueNumber2(e.target.value)}
            placeholder="XXXXXX"
            className="pos-input"
          />
        </label>

        <label htmlFor="dateOfSupply" className="single-input">
          Date of Physical Supply:
          <input
            type="date"
            id="dateOfSupply"
            value={dateOfSupply}
            onChange={(e) => setDateOfSupply(e.target.value)}
            className="pos-input"
          />
        </label>

        <label htmlFor="dateOfIssuance" className="single-input">
          Date of Issuance:
          <input
            type="text"
            id="dateOfIssuance"
            value={dateOfIssuance}
            onChange={(e) => setDateOfIssuance(e.target.value)}
            placeholder="DD.MM.YYYY"
            className="pos-input"
          />
        </label>

        <label htmlFor="contractNumber" className="single-input">
          Contract Number
          <input
            type="text"
            id="contractNumber"
            value={contractNumber}
            onChange={(e) => setContractNumber(e.target.value)}
            placeholder="Unique contract number"
            className="pos-input"
          />
        </label>
      </section>

      <div className="Pos-btnContainer">
        <button className="btn-gonext" type="submit" onClick={handleNext}>
          Go next
          <span className="arrow" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
