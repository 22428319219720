import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/signup/authHeader.scss';
import { useSetRecoilState } from 'recoil';
import {
  currentStepState,
  signupError,
  signupForm1,
} from '../../recoil/authPage';
import { initialFormData } from './signupForms/Signup1';

interface AuthHeaderProps {
  showBackBtn: boolean;
}

export function AuthHeader({ showBackBtn }: AuthHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const setErrorMsg = useSetRecoilState(signupError);
  const setFormData = useSetRecoilState(signupForm1);
  const setCurrentStep = useSetRecoilState(currentStepState);
  const location = useLocation();

  const handleBackClick = () => {
    if (location.pathname === '/userPortal') {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="authHeader">
      {showBackBtn ? (
        <button
          className="arrow-back"
          aria-label="backButton"
          type="button"
          onClick={handleBackClick}
        />
      ) : (
        <div className="spacer" />
      )}

      <button
        className="closeBtn-reset"
        type="button"
        aria-label="Close"
        onClick={() => {
          navigate('/');
          setCurrentStep(0);
          setFormData(initialFormData);
          setErrorMsg({});
          localStorage.removeItem('ID');
        }}
      />
    </div>
  );
}
