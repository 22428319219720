import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { activeOptionId } from '../../recoil/registry';
import sidebarOptions from '../../data/registry/sidebar.json';
import '../../styles/registry/sidebar.scss';

export function Sidebar() {
  const navigate = useNavigate();
  const [activeSidebarId, setActiveSidebarId] = useState<number | null>(
    sidebarOptions.sidebar[0]?.id || null
  );
  const [optionId, setOptionId] = useRecoilState(activeOptionId);

  useEffect(() => {
    const newActiveSidebarId =
      sidebarOptions.sidebar.find((option) =>
        option.options.some((registryOption) => registryOption.id === optionId)
      )?.id || null;

    setActiveSidebarId(newActiveSidebarId);
  }, [optionId]);

  const toggleSidebar = (id: number) => {
    setActiveSidebarId(activeSidebarId === id ? null : id);
  };

  const toggleOption = (Id: number) => {
    const selectedOption = sidebarOptions.sidebar.find((option) =>
      option.options.some((registryOption) => registryOption.id === Id)
    );
    const route = selectedOption?.options.find(
      (registryOption) => registryOption.id === Id
    )?.route;

    if (route) {
      navigate(route);
    }

    setOptionId(optionId === Id ? Id : Id);
  };

  return (
    <div className="sidebar">
      {sidebarOptions.sidebar.map((option) => (
        <div key={option.id} className="card-container">
          <button
            className={classNames('btn-sidebar', {
              active: activeSidebarId === option.id,
            })}
            type="button"
            onClick={() => toggleSidebar(option.id)}
          >
            {option.title.toUpperCase()}
            <div className="btnSvg" />
          </button>

          {activeSidebarId === option.id &&
            option.options.map((registryOption) => (
              <div key={registryOption.id} className="registry">
                <button
                  className={classNames('btn-registry ', {
                    activeRegistry: optionId === registryOption.id,
                  })}
                  type="button"
                  onClick={() => toggleOption(registryOption.id)}
                >
                  {registryOption.name}
                  <div className="btnSvg" />
                </button>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}
