import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import {
  dataUpdateTrigger,
  isSubmissionSuccess,
  massBalanceData,
  registryErrorMsg,
  selectedDateState,
} from '../../../recoil/registry';
import '../../../styles/registry/MassBalance/registryInput.scss';
import { CurrentDate } from './CurrentDate';

const apiURL = process.env.REACT_APP_API_URL;

interface MassBalance {
  selectedDate: string | null;
  unitsBought: number;
  unitsSold: number;
  user: string;
  __v: number;
  _id: string;
}

export function RegistryInput() {
  const [formData, setFormData] = useState({ unitsSold: '', unitsBought: '' });
  const selectedDate = useRecoilValue(selectedDateState);
  const updateDataTrigger = useSetRecoilState(dataUpdateTrigger);
  const [errorMsg, setErrorMsg] = useRecoilState(registryErrorMsg);
  const [submissionSuccess, setSubmissionSuccess] =
    useRecoilState(isSubmissionSuccess);
  const setData = useSetRecoilState(massBalanceData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onDataSave = async () => {
    setErrorMsg(null);
    setSubmissionSuccess(false);
    const userId = localStorage.getItem('ID') || '';

    const unitsSoldNumber = parseFloat(formData.unitsSold);
    const unitsBoughtNumber = formData.unitsBought
      ? parseFloat(formData.unitsBought)
      : 0;

    if (Number.isNaN(unitsSoldNumber)) {
      setErrorMsg('Please enter a valid number for units sold.');
      return;
    }

    const optimisticEntry: MassBalance = {
      selectedDate: selectedDate ? selectedDate.toISOString() : null,
      unitsBought: unitsBoughtNumber || 0,
      unitsSold: unitsSoldNumber,
      user: userId,
      __v: 0,
      _id: `temp-${Date.now()}`,
    };

    setData((prevData) => [...prevData, optimisticEntry]);
    setFormData({ unitsSold: '', unitsBought: '' });

    const onDataSavePayload = {
      unitsSold: unitsSoldNumber,
      unitsBought: unitsBoughtNumber,
      selectedDate,
      userId,
    };

    try {
      const response = await fetch(`${apiURL}/mass-balance`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(onDataSavePayload),
      });

      const dataRes = await response.json();

      if (!response.ok) {
        throw new Error(
          dataRes.message || 'Failed to save data. Please try again.'
        );
      }

      setData((prevData) =>
        prevData.map((item) =>
          item._id === optimisticEntry._id
            ? {
                ...item,
                _id: dataRes._id,
              }
            : item
        )
      );
      updateDataTrigger((currentValue) => currentValue + 1);
      setSubmissionSuccess(true);
      setTimeout(() => {
        setSubmissionSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error:', error);
      setData((prevData) =>
        prevData.filter((item) => item._id !== optimisticEntry._id)
      );
      // setErrorMsg(error.message);
      setSubmissionSuccess(false);
    }
  };

  return (
    <div className="registry-input">
      <CurrentDate />
      <div className="input-container">
        <section>
          <div className="input-title">Units sold (tons)</div>
          <input
            type="text"
            name="unitsSold"
            className={classNames('form-control mbalance', {
              'is-invalid': errorMsg,
              'is-valid': submissionSuccess,
            })}
            onChange={handleChange}
            value={formData.unitsSold}
          />
        </section>

        <div className="wrapper-input">
          <div className="input-title">Units bought (tons)</div>
          <input
            type="text"
            name="unitsBought"
            className={classNames('form-control mbalance', {
              'is-invalid': errorMsg,
              'is-valid': submissionSuccess,
            })}
            onChange={handleChange}
            value={formData.unitsBought}
          />
        </div>
        {errorMsg && <div className="invalid-feedback d-block">{errorMsg}</div>}
      </div>

      <button type="button" className="btn-saveData" onClick={onDataSave}>
        save data
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7.00003L19.59 5.59003L9 16.17Z"
            fill="#0F1E1F"
          />
        </svg>
      </button>
    </div>
  );
}
