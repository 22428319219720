import React from 'react';
import { useRecoilState } from 'recoil';
import { format, parse } from 'date-fns';
import '../../styles/exportComp/selectDays.scss';
import { selectedEndDate, selectedStartDate } from '../../recoil/registry';

export function SelectDays() {
  const [startDate, setStartDate] = useRecoilState(selectedStartDate);
  const [endDate, setEndDate] = useRecoilState(selectedEndDate);
  const formatDate = (date: Date | null): string =>
    date ? format(date, 'd MMM yyyy') : '';

  const parseDate = (dateStr: string): Date | null => {
    try {
      const parsedDate = parse(dateStr, 'd MMM yyyy', new Date());
      return Number.isNaN(parsedDate.getTime()) ? null : parsedDate;
    } catch (error) {
      return null;
    }
  };

  // const isDateSelected = startDate !== null && endDate !== null;
  // console.log(isDateSelected);

  const handleDateChange =
    (setter: (value: Date | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(parseDate(event.target.value));
    };

  return (
    <div className="selectDays">
      <div className="selected">
        <input
          type="text"
          placeholder="DD/MM/YYYY"
          className="dateInput-select"
          // className="editing dateInput"
          value={formatDate(startDate)}
          onChange={handleDateChange(setStartDate)}
        />
        <input
          type="text"
          placeholder="DD/MM/YYYY"
          className="dateInput-select"
          value={formatDate(endDate)}
          onChange={handleDateChange(setEndDate)}
        />
      </div>
    </div>
  );
}
