// CalendarComponent.tsx
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import '../../styles/exportComp/calendarExport.scss';
import { enGB } from 'date-fns/locale';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  datesWithDataOutput,
  selectedEndDate,
  selectedStartDate,
} from '../../recoil/registry';

registerLocale('en-GB', enGB);

export function CalendarExport() {
  const [startDate, setStartDate] = useRecoilState(selectedStartDate);
  const [endDate, setEndDate] = useRecoilState(selectedEndDate);
  const datesWithData = useRecoilValue(datesWithDataOutput);

  const highlightWithClassName = (date: Date) =>
    datesWithData.find(
      (d) => format(d, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    )
      ? 'registered-date'
      : null;

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    // Additional logic if needed
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    // Additional logic if needed
  };

  return (
    <div className="boxtest">
      <div>
        <h2 className="footer-title">Select Start Date</h2>
        <DatePicker
          inline
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          highlightDates={datesWithData}
          dayClassName={highlightWithClassName}
          locale="en-GB"
        />
      </div>

      <div>
        <h2 className="footer-title right">Select End Date</h2>
        <DatePicker
          inline
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate} // Ensures end date is after start date
          dateFormat="yyyy-MM-dd"
          highlightDates={datesWithData}
          dayClassName={highlightWithClassName}
          locale="en-GB"
        />
      </div>
    </div>
  );
}
