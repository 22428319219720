import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../styles/registry/MassBalance/dataTable.scss';
import { useEffect, useState } from 'react';
import {
  dataUpdateTrigger,
  massBalanceData,
  selectedDateState,
} from '../../recoil/registry';

const apiURL = process.env.REACT_APP_API_URL;

export function DataTable() {
  const userId = localStorage.getItem('ID');

  const selectedDate = useRecoilValue(selectedDateState);
  const [data, setData] = useRecoilState(massBalanceData);
  const dataUpdate = useRecoilValue(dataUpdateTrigger);
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const updateDataTrigger = useSetRecoilState(dataUpdateTrigger);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [dateInputs, setDateInputs] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiURL}/mass-balance/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data.massBalances);
      } catch (error) {
        console.error('Failed to fetch dates with data:', error);
      }
    };
    fetchData();
  }, [dataUpdate, userId]);

  const filteredData = data.filter((d) => {
    // Check if d.selectedDate is not null before creating a Date object
    if (d.selectedDate) {
      const dataDate = new Date(d.selectedDate).setHours(0, 0, 0, 0);
      if (selectedDate) {
        const selectedDateNormalized = new Date(selectedDate).setHours(
          0,
          0,
          0,
          0
        );
        return dataDate === selectedDateNormalized;
      }
    }
    return false;
  });

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    field: 'unitsSold' | 'unitsBought'
  ) => {
    const newData = data.map((item) => {
      if (item._id === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setData(newData);
  };

  const handleEditClick = (itemId: string) => {
    setEditingRowId(itemId);
    const currentItem = data.find((item) => item._id === itemId);
    if (currentItem && !dateInputs[itemId]) {
      setDateInputs((inputs) => ({
        ...inputs,
        [itemId]: currentItem.selectedDate
          ? currentItem.selectedDate.slice(0, 10)
          : '',
      }));
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    const previousData = [...data];
    setData(data.filter((item) => item._id !== itemId));

    try {
      const response = await fetch(`${apiURL}/mass-balance/${itemId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (!response.ok) {
        setErrorMsg('Failed to delete the item');
        setData(previousData);
      }
      updateDataTrigger((currentValue) => currentValue + 1);
    } catch (error) {
      console.error('Error:', error);
      setData(previousData);
      setErrorMsg('Failed to delete the item');
    }
  };

  const handleSaveClick = async (itemId: string) => {
    const itemToUpdateIndex = data.findIndex((item) => item._id === itemId);
    if (itemToUpdateIndex === -1) {
      setErrorMsg('Item not found');
      return;
    }

    const newDateInput = dateInputs[itemId];
    const itemToUpdate = data[itemToUpdateIndex];
    const newUnitsSold = itemToUpdate.unitsSold;
    const newUnitsBought = itemToUpdate.unitsBought;

    const hasDataChanged =
      itemToUpdate.selectedDate !== newDateInput ||
      itemToUpdate.unitsSold.toString() !== newUnitsSold.toString() ||
      itemToUpdate.unitsBought.toString() !== newUnitsBought.toString();

    // Check if the data has changed. If not, return early without updating.
    if (!hasDataChanged) {
      setEditingRowId(null);
      return;
    }

    if (!newDateInput) {
      setErrorMsg('Please enter a valid date');
      return;
    }

    const updatedFields = {
      selectedDate: newDateInput,
      unitsSold: newUnitsSold,
      unitsBought: newUnitsBought,
    };

    const newData = [...data];
    newData[itemToUpdateIndex] = { ...itemToUpdate, ...updatedFields };
    setData(newData);
    setEditingRowId(null);

    try {
      const response = await fetch(`${apiURL}/mass-balance/${itemId}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFields),
      });

      if (!response.ok) {
        throw new Error('Failed to save changes');
      }

      updateDataTrigger((currentValue) => currentValue + 1);
      setErrorMsg(null);
    } catch (error) {
      console.error('Error:', error);
      setData([...data]);
      setErrorMsg('Failed to save changes');
    }
  };

  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  const getDateInputValue = (itemId: string): string => {
    if (dateInputs[itemId]) {
      return dateInputs[itemId];
    }
    if (selectedDate) {
      return selectedDate.toISOString().slice(0, 10);
    }
    return '';
  };

  return (
    <div className="dataTable">
      <div className="table-row2">
        <div className="table-left2">
          <div className="table-title datess">Date</div>
          <div className="table-title sold">Sold</div>
          <div className="table-title bought">Bought</div>
        </div>
      </div>

      {filteredData.length > 0 ? (
        filteredData.map((item) => (
          <div key={item._id} className="editingRow">
            {editingRowId === item._id ? (
              // edit State
              <div className="table-celEdit" key={item._id + 1}>
                <input
                  type="date"
                  value={getDateInputValue(item._id)}
                  onChange={(e) =>
                    setDateInputs({ ...dateInputs, [item._id]: e.target.value })
                  }
                  className="editing dateInput"
                />
                <input
                  type="number"
                  value={item.unitsSold}
                  onChange={(e) => handleEditChange(e, item._id, 'unitsSold')}
                  className="editing"
                />
                <input
                  type="number"
                  value={item.unitsBought || ''}
                  onChange={(e) => handleEditChange(e, item._id, 'unitsBought')}
                  className="editing"
                />
              </div>
            ) : (
              // Render read-only data
              <div className="table-celEdit">
                <div className="table-cell date">
                  {item.selectedDate
                    ? new Date(item.selectedDate).toLocaleDateString()
                    : 'N/A'}
                </div>
                <div className="table-cell ">{item.unitsSold}</div>
                <div className="table-cell ">
                  {item.unitsBought !== null ? item.unitsBought : '0'}
                </div>
              </div>
            )}

            {editingRowId === item._id ? (
              <div className="btnEdit-block">
                <button
                  className="check"
                  type="button"
                  aria-label="Save"
                  onClick={() => handleSaveClick(item._id)}
                >
                  accpet
                  <div className="checkSvg" />
                </button>
                <button
                  className="delete"
                  type="button"
                  aria-label="Delete"
                  onClick={() => handleDeleteItem(item._id)}
                >
                  delete
                  <div className="deleteSvg" />
                </button>
              </div>
            ) : (
              <div className="btnblock">
                <button
                  className="btn-edit"
                  type="button"
                  aria-label="Edit"
                  onClick={() => handleEditClick(item._id)}
                >
                  Edit
                  <div className="editIcon" />
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="table-title noData">
          No data available for the selected date.
        </div>
      )}
      {errorMsg && <div className="text-danger">{errorMsg}</div>}
    </div>
  );
}
