import * as XLSX from 'xlsx';
import { format, parseISO, startOfMonth } from 'date-fns';

export const exportToExcel = (
  data: any[],
  startDate: string,
  endDate: string
) => {
  // Filter data based on start and end dates if needed
  const filteredData = data.filter((item) => {
    const itemDate = parseISO(item.selectedDate).getTime();
    return (
      itemDate >= parseISO(startDate).getTime() &&
      itemDate <= parseISO(endDate).getTime()
    );
  });

  // Group and aggregate data by month
  const groupedByMonth = filteredData.reduce((acc, item) => {
    const itemDate = startOfMonth(parseISO(item.selectedDate)).toISOString();
    const monthKey = format(parseISO(itemDate), 'yyyy-MM'); // Simplify key to 'yyyy-MM' format
    if (!acc[monthKey]) {
      acc[monthKey] = {
        Month: monthKey,
        'Units Sold': 0,
        'Units Bought': 0,
      };
    }
    acc[monthKey]['Units Sold'] += item.unitsSold;
    acc[monthKey]['Units Bought'] += item.unitsBought;
    return acc;
  }, {});

  // Convert the aggregated object to an array suitable for the worksheet
  const aggregatedData = Object.values(groupedByMonth);

  // Create the worksheet from the aggregated data
  const worksheet = XLSX.utils.json_to_sheet(aggregatedData);

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Aggregated Data');

  // Generate Excel file and trigger download
  XLSX.writeFile(workbook, 'AggregatedData.xlsx');
};
