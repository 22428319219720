import { atom } from 'recoil';
import { ErrorMessages } from '../components/authPage/signupForms/Signup2';

//////////////SignupPage///////////////
export const signupForm1 = atom({
  key: 'signupForm1',
  default: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: '',
  },
});

export const signupForm2 = atom({
  key: 'signupForm2',
  default: {
    companyName: '',
    address: '',
    country: '',
    scopeOfWork: '',
    businessClassification: '',
    certificationStatus: '',
    phoneNumber: '',
    termAccepted: false,
  },
});

export const signupError = atom<ErrorMessages>({
  key: 'signupError',
  default: {},
});

export const currentStepState = atom({
  key: 'currentStepState',
  default: 2,
});

//////////////LoginPage///////////////
export const loginForm = atom({
  key: 'loginForm',
  default: {
    email: '',
    password: '',
  },
});

export const loginErrorMsg = atom({
  key: 'loginErrorMsg',
  default: '',
});
