import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../../styles/registry/MassBalance/registryHeader.scss';
import BurgerMenu from './massBalance/BurgerMenu';

export function RegistryHeader() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 540);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 540);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onLogout = () => {
    localStorage.removeItem('ID');
    navigate('/login');
  };

  return (
    <div className="registryHeader">
      <div>
        <a
          href="https://www.buymeacoffee.com/Lodret"
          target="_blank"
          rel="noreferrer"
          className="no-underline"
        >
          <button className="headerbtn buyme" type="button">
            Buy Me a coffee
          </button>
        </a>
      </div>

      {isMobile ? (
        <BurgerMenu />
      ) : (
        <div className="btnbox">
          <button className="headerbtn profile" type="button">
            Profile
          </button>
          <button className="headerbtn logout" type="button" onClick={onLogout}>
            Log out
          </button>
        </div>
      )}
    </div>
  );
}
