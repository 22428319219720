import '../../styles/registry/MassBalance/massBalance.scss';

import { useRecoilState } from 'recoil';
import { Calendar } from './massBalance/Calendar';
import { DataTable } from './DataTable';
import { RegistryInput } from './massBalance/RegistryInput';
import { downloadComponent } from '../../recoil/registry';
import { ExportDocument } from '../exportComp/ExportCompon';

export function MassBalance() {
  const [isDownloadComponent, setIsDownloadComponent] =
    useRecoilState(downloadComponent);

  return (
    <div className="massBalance">
      {isDownloadComponent && <ExportDocument />}
      <div className="calendar">
        <Calendar />
      </div>

      <RegistryInput />

      <footer className="footer">
        <div className="footer-title">Download your documents </div>

        <button
          type="button"
          className="btn-download"
          onClick={() => setIsDownloadComponent(!isDownloadComponent)}
        >
          <div className="downloadSvg" />
          Export documents
        </button>
      </footer>

      <DataTable />
    </div>
  );
}
