import { atom } from 'recoil';
import { format } from 'date-fns';
// Get today's date
const today = new Date();

// Format today's date
export const formattedToday = format(today, 'd MMM yyyy');

export const CurrenDate = atom({
  key: 'CurrenDate',
  default: formattedToday,
});

export const selectedDateState = atom<Date | null>({
  key: 'selectedDateState',
  default: today,
});

export const dataUpdateTrigger = atom({
  key: 'dataUpdateTrigger',
  default: 0,
});

export const registryErrorMsg = atom<string | null>({
  key: 'registryErrorMsg',
  default: null,
});

export const isSubmissionSuccess = atom<boolean>({
  key: 'isSubmissionSuccess',
  default: false,
});

interface MassBalance {
  selectedDate: string | null;
  unitsBought: number;
  unitsSold: number;
  user: string;
  __v: number;
  _id: string;
}
export const massBalanceData = atom<MassBalance[]>({
  key: 'massBalanceData',
  default: [],
});

export const downloadComponent = atom<boolean>({
  key: 'downloadComponent',
  default: false,
});

export const datesWithDataOutput = atom<Date[]>({
  key: 'datesWithDataOutput',
  default: [],
});

////////////////// calendar export /////////////////////////////

export const selectedStartDate = atom<Date | null>({
  key: 'selectedStartDate',
  default: today,
});

export const selectedEndDate = atom<Date | null>({
  key: 'selectedEndDate',
  default: null,
});

// Sidebar ID's
export const activeSidebarId = atom<number | null>({
  key: 'activeSidebarId',
  default: null,
});

// Option ID's
export const activeOptionId = atom<number | null>({
  key: 'activeOptionId',
  default: 101,
});
