import { useState } from 'react';
import '../../styles/exportComp/selectMonths.scss';

interface DropdownOption {
  value: string;
  label: string;
}

export function SelectMonths() {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const options: DropdownOption[] = [
    { value: '3 Months', label: '3 Months' },
    { value: '6 Months', label: '6 Months' },
    { value: '9 Months', label: '9 Months' },
    { value: '12 Months', label: '12 Months' },
  ];

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="selectMonths">
      <select
        value={selectedValue}
        onChange={handleDropdownChange}
        className="dropdown-select"
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <div className="fromOptions">
        <button type="button" className="btn-selectStart">
          <div className="from-placeholder">From February 21</div>
          <span className="edit-text">edit</span>
        </button>
      </div>
    </div>
  );
}
