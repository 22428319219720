import '../../styles/utils/stepIndicator.scss'; // Ensure the correct path

type StepIndicatorProps = {
  currentStep: number;
};

function StepIndicator({ currentStep }: StepIndicatorProps) {
  const svgStyle = {
    marginLeft: '16px',
    marginRight: '16px',
    ...(currentStep === 2 && {
      backgroundColor: '#74ec6f',
      borderRadius: '7px',
    }),
  };

  return (
    <div className="step-indicator-container">
      <div className={`step ${currentStep >= 1 ? 'active' : ''}`} />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="53"
        height="12"
        viewBox="0 0 53 12"
        fill="none"
        style={svgStyle}
      >
        <path
          d="M6.5 6H46.5"
          stroke="white"
          strokeOpacity="0.14"
          strokeWidth="12"
          strokeLinecap="round"
        />
      </svg>

      <div className={`step ${currentStep === 2 ? 'active' : ''}`} />
    </div>
  );
}

export default StepIndicator;
