import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './components/authPage/LoginPage';
import { UserPortal } from './components/registry/UserPortal';
import { Main } from './components/landingPage/main';
import { SignupPage } from './components/authPage/SignupPage';
import { ForgotPassword } from './components/authPage/ForgotPassword';
import { ResetPassword } from './components/authPage/ResetPassword';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/userPortal/*" element={<UserPortal />} />
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}
export default App;
