// import { useRecoilValue } from 'recoil';
import { Route, Routes } from 'react-router-dom';
import '../../../styles/registry/PoS/Pos.scss';
import { AddRecord } from './AddRecord';
import { Step1 } from './Forms/Step1';
import { Step2 } from './Forms/Step2';

export function PoS() {
  return (
    <div className="PoS">
      <h1 className="title-Pos">
        Proof of Sustainability (PoS) for the delivery of biomass fuels
      </h1>
      <Routes>
        <Route path="AddRecord" element={<AddRecord />} />
        <Route path="step1" element={<Step1 />} />
        <Route path="step2" element={<Step2 />} />
      </Routes>
    </div>
  );
}
