import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import StepIndicator from '../../utils/StepIndicator';
import formFields from '../../../data/signupPage2.json';
import { dataType } from '../../../utils/authPage/signupUtils2';
import {
  currentStepState,
  signupError,
  signupForm2,
} from '../../../recoil/authPage';

export interface ErrorMessages {
  [key: string]: string;
}

type Field = {
  id: string;
  type: string;
  placeholder: string;
  name: string;
  autocomplete?: string;
  options?: string[];
};

const initialFormData = {
  companyName: '',
  address: '',
  country: '',
  scopeOfWork: '',
  businessClassification: '',
  certificationStatus: '',
  phoneNumber: '',
  termAccepted: false,
};
const apiURL = process.env.REACT_APP_API_URL;

export function Signup2() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const [formData, setFormData] = useRecoilState(signupForm2);
  const [errorMsg, setErrorMsg] = useRecoilState<ErrorMessages>(signupError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      termAccepted: e.target.checked,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (phone: string | undefined) => {
    setFormData({ ...formData, phoneNumber: phone || '' });
  };

  const userId = localStorage.getItem('ID');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiURL}/users/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log('data', data);

      if (!response.ok) {
        const errorMessages = data.errors || {};
        if (data.message) {
          errorMessages.general = data.message;
        }
        setErrorMsg(errorMessages);
        return;
      }

      setFormData(initialFormData);
      setErrorMsg({});
      setCurrentStep(0);
      navigate('/login');
    } catch (error: unknown) {
      console.error('Error:', error);
      console.log(userId, 23232);
    }
  };

  const triggerFormSubmit = () => {
    const hiddenSubmitButton = document.getElementById('hiddenSubmit');
    if (hiddenSubmitButton) {
      hiddenSubmitButton.click();
    }
  };

  return (
    <div className="form-layout">
      <StepIndicator currentStep={currentStep} />
      <h1 className="title-green">{formFields.title}</h1>
      <h6 className="subtitle-green">{formFields.subtitle}</h6>

      <form className="green-input-container" onSubmit={handleSubmit}>
        <button
          id="hiddenSubmit"
          type="submit"
          style={{ display: 'none' }}
          aria-label="Go to next step"
        />
        {formFields.fields.map((field: Field) => (
          <React.Fragment key={field.id}>
            <div className="form-name">{field.placeholder}</div>
            {field.type === 'dropdown' ? (
              <div className="dropdown-container">
                <select
                  name={field.name}
                  value={formData[field.name as keyof dataType]}
                  onChange={handleSelectChange}
                  className={classNames('form-control select pointer', {
                    'is-invalid': errorMsg[field.name],
                    'is-valid':
                      formData[field.name as keyof dataType] &&
                      !errorMsg[field.name],
                  })}
                >
                  <option value="">Select...</option>
                  {field.options?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                autoComplete={field.autocomplete || 'off'}
                value={formData[field.name as keyof dataType]}
                onChange={handleChange}
                className={classNames('form-control', {
                  'is-invalid': errorMsg[field.name],
                  'is-valid':
                    formData[field.name as keyof dataType] &&
                    !errorMsg[field.name],
                })}
              />
            )}
            {errorMsg[field.name] && (
              <div className="invalid-feedback d-block">
                {errorMsg[field.name]}
              </div>
            )}
          </React.Fragment>
        ))}

        <div className="form-name">Phone number</div>
        <PhoneInput
          international
          defaultCountry="LV"
          value={formData.phoneNumber}
          onChange={handlePhoneChange}
          autoComplete="tel"
          className={classNames('phoneInput', 'phone-input', 'input', {
            'is-invalid': errorMsg[PhoneInput.name],
            'is-valid': formData.phoneNumber && !errorMsg[PhoneInput.name],
          })}
        />
        {errorMsg.phoneNumber && (
          <div className="invalid-feedback d-block">{errorMsg.phoneNumber}</div>
        )}
      </form>

      <label className="terms-label text-white ">
        <input
          type="checkbox"
          id="acceptTerms"
          checked={formData.termAccepted}
          onChange={handleTermsChange}
          className="terms-checkbox"
        />
        I agree with
        <a href="/terms" className="ms-2 terms-link">
          Terms and Policies
        </a>
      </label>

      {errorMsg.termAccepted && (
        <div className="invalid-feedback d-block ms-2 mt-2">
          {errorMsg.termAccepted}
        </div>
      )}

      <button className="btn-save" type="submit" onClick={triggerFormSubmit}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7.00003L19.59 5.59003L9 16.17Z"
            fill="#0F1E1F"
          />
        </svg>
        Save
      </button>
    </div>
  );
}
