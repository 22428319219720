import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { currentStepState } from '../../recoil/authPage';
import { Signup1 } from './signupForms/Signup1';
import { Signup2 } from './signupForms/Signup2';
import { AuthHeader } from './AuthHeader';
import '../../styles/signup/signupPage.scss';

export function SignupPage() {
  const [currentStep] = useRecoilState(currentStepState);
  const [transitionStep, setTransitionStep] = useState(currentStep);
  const showBackBtn = false;

  // Trigger transition on step change
  useEffect(() => {
    // Fade out form
    const timer = setTimeout(() => {
      setTransitionStep(currentStep);
    }, 500); // Matches the CSS transition duration

    return () => clearTimeout(timer);
  }, [currentStep]);

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Signup1 />;
      case 2:
        return <Signup2 />;
      default:
        return null;
    }
  };

  return (
    <>
      <AuthHeader showBackBtn={showBackBtn} />
      <div className="layout">
        <div
          className={`formtest ${
            transitionStep === currentStep ? 'active' : ''
          }`}
        >
          {renderCurrentStep()}
        </div>

        <div className="island auth" />
      </div>
    </>
  );
}
